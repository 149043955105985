<template>
  <div>
    <div class="relatedMainLayout" v-if="dataFetched">
      <div :class="[localDisplayLang === 'ara' ? 'relatedCardsAra' : 'relatedCards']">
        <!-- <div class="card"  v-for="related in relateds" :key="related.objectid"> -->

        <div v-if="relatedContentDetails && relatedContentDetails.length == 0" class="error-message">
          <p> {{$t("No contents found")}} </p>
        </div>

        <div v-else class="relatedCard" v-for="related in relatedContentDetails" :key="related.objectid">
          <div class="relatedContainer" @click="navigateToDetail(related)">
            <div class="relatedPosterLayout">
              <img :src="getPoster(related)" class="relatedPoster" alt="" />
            </div>
            <div v-if="isObjectTag(related)" :class="[localDisplayLang === 'ara' ? 'relatedTagRight' : 'relatedTag']">
              <p class="tag">{{ pickTagForContent(related) }}</p>
            </div>
            <div class="relatedContentLayout">
              <div class="relatedContentSplitter">
                <p class="relatedTitle">{{ related.title }}</p>
                <!-- <p class="related-duration">{{ calcTimeFormat(300) }}</p> -->
              </div>
              <p class="relatedDescription" v-if="related.shortdescription || related.longdescription">
                <!-- {{
                  related.longdescription
                    ? related.longdescription.length > 150
                      ? related.longdescription.slice(0, 150)
                      : related.longdescription
                    : related.shortdescription.slice(0, 150)
                }} -->

                {{ getDescription(related) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!dataFetched">
      <Loading></Loading>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
export default {
  props: {
    contentDetails: {
      type: Object,
    },
  },
  data() {
    return {
      dataFetched: false,
      seasonNum: 1,
      seasonCount: null,
      localDisplayLang: null,
      seasonCountArray: [],
      isMpegRequired: false,
      relatedContentDetails: null,
      containerHover: "container",
      noContainerHover: "container-no-hover",
    };
  },

  computed: {
    checkSeries: function() {
      if (this.contentDetails.category == "MOVIE") {
        return false;
      } else {
        return true;
      }
    },

    // getDescription() {

    //   if(this.related.shortdescription && this.related.shortdescription.length > 250) {
    //     return this.related.shortdescription.slice(0, 250);
    //   } else if (this.related.longdescription && this.related.longdescription.length > 250) {
    //     return this.related.longdescription.slice(0, 250);
    //   } else if(this.related.shortdescription) {
    //     return this.related.shortdescription;
    //   } else if(this.related.longdescription) {
    //     return this.related.longdescription;
    //   }

    // }
  },
  created() {
    console.log("this.contentDetails in related card---", this.contentDetails);
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    this.fetchRelatedContents(this.contentDetails.objectid);
  },
  mounted() {
    eventBus.$on("relatedcontents-response", (response) => {
      console.log("Received related response in related card:----", response);
      this.dataFetched = true;

      if(!response.errorcode) {
        this.relatedContentDetails = response.data;
      } else {
        this.relatedContentDetails = [];
      }
    });
  },
  methods: {

    getDescription(related) {

      if(related.shortdescription && related.shortdescription.length > 250) {
        return related.shortdescription.slice(0, 250);
      } else if (related.longdescription && related.longdescription.length > 250) {
        return related.longdescription.slice(0, 250);
      } else if(related.shortdescription) {
        return related.shortdescription;
      } else if(related.longdescription) {
        return related.longdescription;
      }

    },
    pickTagForContent(content) {
      if (content.hasOwnProperty("objecttag")) {
        let tag = content.objecttag[0];
        let contenttags = this.appConfig.contentTags;
        if (this.localDisplayLang === "ara") {
          return contenttags.ar[`${tag}`];
        } else {
          return contenttags.en[`${tag}`];
        }
      }
    },
    isObjectTag(content) {
      if (content.hasOwnProperty("objecttag")) {
        if (Array.isArray(content.objecttag)) {
          return content.objecttag.length === 0 ? false : true;
        } else {
          return content.objecttag === null ? false : true;
        }
      }
    },
    fetchRelatedContents(contentId) {
      let pageSize = 15;
      //let pageNum = Math.floor(this.episodeList.length / pageSize) + 1;

      let payload = {
        contentid: contentId,
        params: {
          displaylanguage: this.localDisplayLang,
          pagesize: pageSize,
          // page: pageNum,
        },
      };

      if (this.isMpegRequired == true) {
        payload.params.mpegtspackage = "YES";
      }
      console.log("fetch related contents---------", payload);
      eventBus.$emit("getRelatedcontents", payload);
    },

    getPoster(content) {
      if (content && content.poster && content.poster.length && !content.inwatchlist) {
        let index = content.poster.findIndex((element) => {
          return element.postertype === "LANDSCAPE";
        });

        if (index > -1) {
          return content.poster[index].filelist[1].filename;
        }
      } else if (content.inwatchlist) {
        return content.poster.landscape;
      }
    },
    navigateToDetail(relatedItem) {
      console.log("what is the item selected-----------------", relatedItem);
      sessionStorage.setItem("isActiveDesktopView", true);
      //  if(relatedItem.category == "TVSHOW"){
      //       this.$router.push({ name: "detailPage", params: {content: relatedItem , contentId: relatedItem.objectid  } });

      //     }
      //     else{
      //       this.$router.push({ name: "detailPageMovie", params: {contentName: relatedItem.title , contentId: relatedItem.objectid  } });
      //     }
      if (relatedItem.category == "TVSHOW") {
        // let title;
        // if (this.localDisplayLang == "eng") {
        //   title = relatedItem.title.replace(/\s/g, "-");
        // } else {
        //   title = "title";
        // }
          let title = relatedItem.defaulttitle.replace(/\s/g, "-");
          let currentLanguage = this.getCurrentLanguageUrlBase();

        this.$router.push({ name: "detailPage", params: { contentId: relatedItem.objectid.toLowerCase(), mediaTitle: title.toLowerCase(), lang: currentLanguage } });
      } else {
        // let title;
        // if (this.localDisplayLang == "eng") {
        //   title = relatedItem.title.replace(/\s/g, "-");
        // } else {
        //   title = "title";
        // }
          let title = relatedItem.defaulttitle.replace(/\s/g, "-");
        this.$router.push({ name: "detailPageMovie", params: { mediaTitle: title, contentId: relatedItem.objectid } });
      }
    },
  },
  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },
  mixins: [Utility],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.error-message {
  width: inherit;
  text-align: center;
  margin : 2rem 0 0 0;
  font-family: $font-regular;
  color: #ffffff;

}

.relatedMainLayout {
  .relatedCards {
    width: 100%;
    // max-width: 1200px;
    // margin-right: 40px;
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    // grid-column-gap: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 36px;

    .relatedCard {
      border-radius: 4px;
      margin-right: 55px;
      margin-top: 30px;
      cursor: pointer;
      .relatedContainer {
        width: 250px;
        height: 190px;
        position: relative;
        transition: transform 450ms;
      }
      .relatedContainer:hover {
        z-index: 10;
        transform: scale(1.2);
        .relatedContentLayout {
          height: auto;
        }
        .relatedDescription {
          display: block;
          color: #ffffff;
          font-size: 0.8rem;
          opacity: 0.6;
          text-align: left;
          line-height: 15px;
          font-family: $font-regular;
          margin-bottom: 10px;
          line-height: 18px;
        }
      }
      .relatedPosterLayout {
        width: auto;
        position: absolute;
        /* for demo purpose  */
        .relatedPoster {
          width: auto;
          height: 165px;
          border-radius: 4px;
        }
      }
      .relatedTag {
        position: absolute;
        top: 2%;
        left: 0%;
        color: #fff;
        background: $btn-clr-new;
        font-family: $font-regular;
        padding: 5px 10px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        .tag {
          font-size: 0.7rem;
        }
      }
      .relatedTagRight {
        position: absolute;
        top: 2%;
        right: 0%;
        color: #fff;
        background: $btn-clr-new;
        font-family: $font-regular;
        padding: 5px 10px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        .tag {
          font-size: 0.8rem;
        }
      }
      .play-img {
        width: 294px;
        height: 20%;
        position: absolute;
        top: 25%;
        opacity: 0.8;
        z-index: 9;
      }
      .relatedContentLayout {
        width: 294px;
        height: 20%;
        position: absolute;
        top: 84%;
        opacity: 1;
        z-index: 9;
        margin: 0px; /* for demo purpose  */
        background-color: rgb(31, 30, 30);

        .relatedContentSplitter {
          display: flex;
          padding: 10px;
          justify-content: space-between;

          .relatedTitle {
            color: #ffffff;
            font-family: $font-regular;
            font-size: 1rem;
            font-weight: 600;
          }
          .relatedDuration {
            color: #ffffff;
            font-family: $font-regular;
            font-size: 0.8rem;
            opacity: 0.8;
          }
        }
        .relatedDescription {
          padding: 0px;
          margin-left: 10px;
          margin-right: 10px;
          display: none;
          color: #ffffff;
          font-size: 0.8rem;
          opacity: 0.6;
          font-family: $font-regular;
        }
      }
    }
  }
  .relatedCardsAra {
    margin-top: 18px;
    width: 100%;
    // max-width: 1200px;
    // margin-right: 40px;
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    // grid-column-gap: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 36px;
    margin-right: 40px;
    .relatedCard {
      border-radius: 4px;
      margin-left: 55px;
      margin-top: 30px;
      cursor: pointer;
      .relatedContainer {
        width: 250px;
        height: 190px;
        position: relative;
        transition: transform 450ms;
      }
      .relatedContainer:hover {
        z-index: 10;
        transform: scale(1.2);
        .relatedContentLayout {
          height: auto;
        }
        .relatedDescription {
          display: block;
          color: #ffffff;
          font-size: 0.8rem;
          opacity: 0.6;
          text-align: right;
          line-height: 15px;
          font-family: $font-regular;
          margin-bottom: 10px;
          line-height: 18px;
        }
      }
      .relatedPosterLayout {
        width: auto;
        position: absolute;
        /* for demo purpose  */
        .relatedPoster {
          width: auto;
          height: 165px;
          border-radius: 4px;
        }
      }
      .relatedTag {
        position: absolute;
        top: 2%;
        left: 0%;
        color: #fff;
        background: $btn-clr-new;
        font-family: $font-regular;
        padding: 5px 10px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        .tag {
          font-size: 0.7rem;
        }
      }
      .relatedTagRight {
        position: absolute;
        top: 2%;
        right: 0%;
        color: #fff;
        background: $btn-clr-new;
        font-family: $font-regular;
        padding: 5px 10px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        .tag {
          font-size: 0.8rem;
        }
      }
      .play-img {
        width: 294px;
        height: 20%;
        position: absolute;
        top: 25%;
        opacity: 0.8;
        z-index: 9;
      }
      .relatedContentLayout {
        width: 294px;
        height: 20%;
        position: absolute;
        top: 84%;
        opacity: 1;
        z-index: 9;
        margin: 0px; /* for demo purpose  */
        background-color: rgb(31, 30, 30);

        .relatedContentSplitter {
          display: flex;
          padding: 10px;
          justify-content: space-between;

          .relatedTitle {
            color: #ffffff;
            font-family: $font-regular;
            font-size: 1rem;
          }
          .relatedDuration {
            color: #ffffff;
            font-family: $font-regular;
            font-size: 0.8rem;
            opacity: 0.8;
          }
        }
        .relatedDescription {
          padding: 0px;
          margin-left: 10px;
          margin-right: 10px;
          display: none;
          color: #ffffff;
          font-size: 0.8rem;
          opacity: 0.6;
          font-family: $font-regular;
        }
      }
    }
  }
}

@media only screen and (min-width: 400px) and (max-width: 576px) {
  .relatedMainLayout {
    margin-right: 0px;
    margin-left: 0px;

    .relatedCards {
      .relatedCard {
        margin-top: 40px;
        margin-right: 20px;
        .relatedPosterLayout {
          width: auto;
          position: absolute;
          /* for demo purpose  */
          .relatedPoster {
            width: auto;
            height: 180px;
            border-radius: 4px;
          }
        }
        .play-img {
          width: 324px;
          height: 20%;
          position: absolute;
          top: 30%;
          opacity: 0.8;
          z-index: 9;
        }
        .relatedContentLayout {
          margin-top: 15px;
          width: 324px;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: portrait) {
  .relatedMainLayout {
    margin-right: 0px;
    margin-left: 0px;

    .relatedCards {
      .relatedCard {
        margin-right: 110px;
        margin-top: 48px;
        .relatedPosterLayout {
          width: auto;
          position: absolute;
          /* for demo purpose  */
          .relatedPoster {
            width: auto;
            height: 190px;
            border-radius: 4px;
          }
        }
        .play-img {
          width: 340px;
        }

        .relatedContentLayout {
          top: 98%;
          width: 340px;
        }
      }
    }
    .relatedCardsAra {
      margin-right: 20px;
      .relatedCard {
        margin-right: 0px;
        margin-left: 110px;
        margin-top: 48px;
        .relatedPosterLayout {
          width: auto;
          position: absolute;
          /* for demo purpose  */
          .relatedPoster {
            width: auto;
            height: 190px;
            border-radius: 4px;
          }
        }
        .play-img {
          width: 340px;
        }

        .relatedContentLayout {
          top: 98%;
          width: 340px;
        }
      }
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
  .relatedMainLayout {
    margin-right: 0px;
    margin-left: 0px;

    .relatedCards {
      .relatedCard {
        margin-right: 70px;
        margin-top: 20px;
        .relatedPosterLayout {
          width: auto;
          position: absolute;
          /* for demo purpose  */
          .relatedPoster {
            width: auto;
            height: 170px;
            border-radius: 4px;
          }
        }
        .play-img {
          width: 304px;
        }

        .relatedContentLayout {
          top: 80%;
          width: 304px;
        }
      }
    }
    .relatedCardsAra {
      margin-right: 20px;
      .relatedCard {
        margin-left: 70px;
        margin-top: 20px;
        .relatedPosterLayout {
          width: auto;
          position: absolute;
          /* for demo purpose  */
          .relatedPoster {
            width: auto;
            height: 170px;
            border-radius: 4px;
          }
        }
        .play-img {
          width: 304px;
        }

        .relatedContentLayout {
          top: 80%;
          width: 304px;
        }
      }
    }
  }
}

@media (min-width: 1400px) and (max-width: 1919px) {
  .relatedMainLayout {
    .relatedCards {
      .relatedCard {
        margin-right: 25px;
        margin-top: 20px;
        .relatedPosterLayout {
          width: auto;
          position: absolute;
          /* for demo purpose  */
          .relatedPoster {
            width: auto;
            height: 145px;
            border-radius: 4px;
          }
        }
        .play-img {
          width: 260px;
        }

        .relatedContentLayout {
          top: 70%;
          width: 260px;
        }
      }
    }
    .relatedCardsAra {
      .relatedCard {
        margin-left: 25px;
        margin-top: 20px;
        .relatedPosterLayout {
          width: auto;
          position: absolute;
          /* for demo purpose  */
          .relatedPoster {
            width: auto;
            height: 145px;
            border-radius: 4px;
          }
        }
        .play-img {
          width: 260px;
        }

        .relatedContentLayout {
          top: 70%;
          width: 260px;
        }
      }
    }
  }
}
</style>
